/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~@fortawesome/fontawesome-free/css/all.css";

.active-item {
    background-color: #2d3393;
    color: white;
  }
  
  #userManagementDropdown {
    transition: opacity 0.1s ease-in-out;
    opacity: 0;
  }
  
  #userManagementDropdown:not(.hidden) {
    opacity: 1;
  }
  
  .dropdown {
    display: none;
  }
  
  .dropdown.open {
    display: block;
  }
  
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    /* Prevent horizontal scrollbars */
    font-family: "fira-sans", sans-serif;
    /* Add your preferred font-family */
  }
  
  nav {
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
  
  aside {
    position: fixed;
    height: 100%;
    overflow-y: auto;
    z-index: 999;
  }
  
  #userManagementDropdown {
    /* content: ""; */
    border-left: 2px solid #2d3393;
    padding-left: 15px;
  }
  
  .main-container {
    margin-top: 4%;
    /* Adjust based on your navbar height */
    margin-left: 18%;
    /* Adjust based on your sidebar width */
    padding: 20px;
    /* Adjust based on your design requirements */
    box-sizing: border-box;
  }
  
  .profile-image {
    border: 2px solid #2d3393;
    /* Set the border style and color */
    border-radius: 150px;
    /* Set the border radius for rounded corners */
    height: 120px;
  }
  
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
